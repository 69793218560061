body {
  color: $text-color;
  font-size: 14px;
}

a,
a:hover {
  text-decoration: none;
  color: $text-color;
}

p {
  margin: 0;
}

label {
  margin: 0;
}

img {
  max-width: 100%;
}

.color-main {
  color: $main-color;
}

.color-sub {
  color: $sub-color;
}

.color-nav {
  color: $nav-color;
}

.color-alert {
  color: $alert-color;
}

.color-reset {
  color: #4c4cff;
}

.color-note {
  color: #2c3e50;
}

.color-disable {
  color: $color-disable;
}

.color-active {
  color: $color-active;
}

.background-disable {
  background: $color-disable;
}

.background-active {
  background: $color-active;
}

.color-active {
  color: $color-active;
}

.background-main {
  background: $main-color;
}

.background-nav {
  background: $nav-color;
}

.background-alert {
  background: $alert-color;
}

.white-space-nowrap {
  white-space: nowrap;
}

.font-bold {
  font-weight: bold;
}
