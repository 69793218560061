@font-face {
    font-family: 'E111Viva';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/E111Viva.ttf') format('truetype');
}

@font-face {
    font-family: 'Spiral';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Spiral.ttf') format('truetype');
}

@font-face {
    font-family: 'Calendas_Plus';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Calendas_Plus.otf') format('opentype');
}

.gallery-category-item {
    background: url('./assets/img/banner-gallery.jpg');
    background-size: cover;
  background-position: center center;
}