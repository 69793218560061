// Variable overrides
$bg-primary: #f59e2d;
$bg-sub: #0b5b76;

$main-color: #0b5b76;
$sub-color: #f59e2d;
$text-color: #21051b;
$nav-color: #d67e00;
$alert-color: #ff0000;

$color-disable: #666666;
$color-active: #0b7620;

$font-size-main: 14px
