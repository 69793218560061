@import "variables";

// scss-lint:disable all
.animated {
  animation-duration: 1s;
  // animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@mixin transition {
  transition: all 0.35s ease-in-out;
}

@mixin col-x-list {
  @for $i from 1 through 100 {
    .w-#{$i} {
      width: $i * 1%;
    }
  }
}

@include col-x-list;

button {
  &:hover,
  &:focus,
  &:visited {
    outline: none;
  }
}

body {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

header {
  // box-shadow: 0 0 5px 0 #ccc;
  padding: 0 15px;
  // height: 55px;
  z-index: 10;
  width: 100%;
  background: linear-gradient(to right, #f7b532, #f38d2a);
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 83px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @media (max-width: 767px) {
    height: initial;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    // border-bottom: 1px solid #fff;
    padding: 15px 0;
    flex-wrap: wrap;
  }

  .header-menu {
    padding: 0 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 13px;
    @media (max-width: 767px) {
      display: none;
    }
    a {
      margin-right: 5px;
      padding: 8px 15px;
      color: #fff;
      display: inline-flex;
      @media (max-width: 767px) {
        display: flex;
        width: 100%;
      }
      @media (max-width: 991px) {
        padding: 8px 12px;
      }
      &.selected {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 6px;
      }
    }
  }
  .header-right {
    padding: 0 15px;
    margin-left: auto;
    display: flex;

    @media (max-width: 767px) {
      padding: 0;
    }

    .header-dropdown-left,
    .header-dropdown-left.dropdown-toggle,
    .header-dropdown-right,
    .header-dropdown-right.dropdown-toggle {
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      font-weight: 500;
      color: #fff;
    }

    .dropdown-toggle {
      display: flex;
      align-items: center;
      span {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 15px;
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 2px;
        background: #fff;
        overflow: hidden;
        img {
          object-fit: cover;
        }
      }
      &:after {
        margin-left: 15px;
      }
      > p {
        @media (max-width: 991px) {
          max-width: 75px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.home-dropdown {
  padding: 5px 0;
  border-radius: 15px;
  border: none;
  background: #f4f5f7;
  overflow: hidden;
  box-shadow: 0px 1px 3px #ccc;
  &.bg-white {
    background: #fff;
  }

  &.dashboard-dropdown {
    background: #d5d5d5;
    padding: 10px 20px;

    button {
      padding: 10px;
      border-bottom: 1px solid #e9e9e9;
      font-weight: bold;
      &:hover,
      &:focus {
        background: #d5d5d5;
        color: #333;
        outline: none;
      }

      &:last-child {
        border: none;
      }
    }
  }

  button {
    padding: 10px 25px;
    display: flex;
    align-items: center;
  }
}

.dashboard-search {
  @media (max-width: 767px) {
    width: 100%;
    .dashboard-dropdown-toggle {
      margin-bottom: 15px;
      margin-right: 0;
      width: 100%;
    }
  }
}

.dashboard-dropdown-toggle {
  min-width: 130px;
  text-align: left;
  margin-right: 50px;
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  font-weight: bold;
  padding-right: 30px;
  min-height: 36px;

  &:after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.form-search {
  display: flex;
  font-size: 15px;
  color: #333;

  &.form-dashboard-mb {
    @media (max-width: 767px) {
      width: 100%;
      label {
        display: none;
      }

      .react-datepicker-wrapper,
      .date-picker-right {
        flex: 1;
        width: 100%;
        .react-datepicker__input-container {
          width: 100%;
          input {
            margin: 0;
            max-width: 100%;
          }
        }
      }
    }
  }

  label {
    margin-right: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  input {
    max-width: 120px;
    padding: 5px 10px;
    height: initial;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    text-align: center;
    font-weight: bold;
    color: #333;

    &.input-keyword {
      max-width: 200px;
      text-align: left;
    }

    &:focus {
      background: rgba(0, 0, 0, 0.1);
      outline: none;
      box-shadow: none;
    }

    &.input-search-staff {
      height: 36px;
    }
  }
  span {
    margin: 0 10px;
    display: flex;
    align-items: center;
  }

  .btn-search {
    border: 1px solid #fff;
    color: #fff;
    background: #0b5b76;
    border-radius: 5px;
    min-width: 120px;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 20px;
  }

  &.bar-setting {
    button {
      background: none;
      color: #333;
      font-weight: bold;
      border: none;
      margin-right: 10px;
      padding: 0;
    }
  }

  &.form-search-setting {
    button {
      background: none;
      color: #333;
      font-weight: bold;
      margin-right: 30px;
      border: none;
      padding: 0;
      &:focus,
      &:visited {
        box-shadow: none;
        outline: none;
      }

      &.selected {
        color: #fff;
      }
    }
  }
}

.form-search-reviews {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > input {
    margin-right: 1rem;
    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .form-control {
    width: initial;
  }
  input {
    line-height: 35px;
    background: #f0f0f0;
    border: none;
    border-radius: 20px;
    outline: none;
    box-shadow: none;
    font-size: 15px;
    padding: 0 30px 0 15px;

    &.input-reviews-time {
      max-width: 140px;
      @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .react-datepicker__input-container {
    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      content: "\f073";
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      color: #f4992d;
    }
  }

  > button {
    line-height: 35px;
    min-width: 120px;
    background: #f4992d;
    color: #fff;
    border: none;
    font-size: 15px;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 1rem;
    @include transition();
    &:hover,
    &:focus,
    &:visited {
      background: darken(#f4992d, 10%);
    }
  }

  @media (max-width: 767px) {
    .react-datepicker-wrapper {
      width: 100%;
      margin-bottom: 15px;
    }

    > button {
      margin-left: 0;
    }
    > span {
      display: none;
    }
  }
}

.form-group-reviews {
  .header-reviews {
    display: flex;
    align-items: flex-end;
    background: #f5f6f9;
    padding: 10px 0;
    > div {
      flex: 1 1;
      font-size: 14px;
      color: #565861;
      font-weight: bold;
      padding-left: 15px;
    }
    @media (max-width: 767px) {
      background: #fff;
      padding-left: 40px;
    }
  }

  .body-reviews {
    > div {
      &:first-child {
        .row-reviews {
          .row-content {
            border-top: 1px solid #c8c7c6;
          }
        }
      }
      &:last-child {
        .row-reviews {
          .row-content {
            border: none;
          }
        }
      }
      .row-reviews {
        .row-content {
          display: flex;
          border-bottom: 1px solid #c8c7c6;
          padding: 10px 0;
          position: relative;
          align-items: center;
          .icon-xs {
            position: absolute;
            left: 10px;
            font-size: 20px;
            top: 50%;
            transform: translate(0, -50%);
            line-height: 14px;
            color: #f48e2b;
            @include transition();
            @media (min-width: 768px) {
              display: none;
            }
            &.rotate {
              transform: translate(0, -50%) rotate(90deg);
            }
          }
          @media (max-width: 767px) {
            padding-left: 40px;
          }
          > div {
            flex: 1;
            padding-left: 15px;
          }
        }
        .row-collapse {
          margin-left: 40px;

          .row-xs-reviews {
            width: 80%;
            border: 1px solid #c8c7c6;
            border-top: none;
            > div {
              display: flex;
              flex-wrap: wrap;
              padding: 5px 10px;
              border-bottom: 1px solid #c8c7c6;
              &:last-child {
                border: none;
              }
              b,
              p {
                width: 50%;
              }
              p {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 350px) {
    font-size: 12px;
    .header-reviews {
      > div {
        font-size: 13px;
        white-space: nowrap;
      }
    }
    .body-reviews > div .row-reviews .row-collapse .row-xs-reviews {
      width: 100%;
    }
  }
}

.hidden-xs {
  @media (max-width: 767px) {
    display: none;
  }
}

.layout-home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 83px;
}

.layout-body {
  flex: 1;
  position: relative;
  max-width: 100%;

  @include transition();

  .layout-sidebar {
    width: 250px;
    height: calc(100vh - 55px);
    padding: 40px 15px;
    position: fixed;
    left: 0;
    top: 55px;
    display: flex;
    flex-direction: column;
    @include transition();

    .sidebar-info {
      margin-bottom: 30px;

      .sidebar-info-meta {
        width: 55px;
        margin-right: 15px;
      }

      .sidebar-info-content {
        width: calc(100% - 65px);

        h5 {
          font-weight: bold;
          margin-bottom: 5px;
        }
        p {
          font-size: 19px;
          font-weight: 500;
        }
      }
    }

    .sidebar-card {
      padding: 15px 20px;
      background: $main-color;
      margin-bottom: 30px;
      color: #fff;
      border-radius: 15px;
      text-align: center;

      h4 {
        font-weight: bold;
      }
    }

    a {
      padding: 10px 15px;
      display: block;
      border-radius: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      display: flex;
      align-items: center;
      @include transition;

      &.selected,
      &:hover {
        background: $nav-color;
        color: #fff;
        box-shadow: 0 2px 6px 0px #bbb;
      }

      svg {
        margin-right: 15px;
      }
    }
    .aside-btn {
      margin-top: auto;
      padding: 0 30px;
      button {
        margin-top: auto;
        padding: 15px 0;
        background: none;
        color: $main-color;
        border-radius: 30px;
        border: 1px solid $main-color;
        box-shadow: 0 0 10px #999;
        width: 100%;
      }
    }
  }

  @media (max-width: 991px) {
    padding: -0;
    .layout-sidebar {
      left: -100%;
    }
  }

  .layout-main {
    // background: #eeeeee;
    width: 100%;

    // padding: 30px 20px 0;
    // min-height: calc(100vh - 55px);
  }
}

.login-form {
  z-index: 2;
  width: 500px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 0px 10px #fff;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 30px);
  .login-title {
    margin-top: 0;
    text-align: center;
  }

  .login-logo {
    display: inline-flex;

    @media (max-width: 767px) {
      max-height: 150px;
    }
    img {
      object-fit: contain;
    }
  }

  form {
    .form-group {
      padding: 0 15px;
      position: relative;
      transition: all 0.3s ease-in-out;

      &.focus {
        label {
          z-index: 1;
          top: 0px;
          background: #fff;
          font-size: 15px;
        }
      }

      label {
        position: absolute;
        top: 50%;
        left: 25px;
        transform: translate(0, -50%);
        margin: 0;
        color: #495058;
        font-weight: 500;
        z-index: -1;
        padding: 0 10px;
        transition: all 0.3s ease-in-out;
        font-size: 20px;
      }

      input {
        &.form-control {
          padding-top: 10px;
          padding-bottom: 10px;
          height: initial;
          background: transparent;
          font-size: 14px;
          line-height: 30px;
          @media (max-width: 767px) {
            line-height: initial;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }

      button {
        font-weight: 500;
        background: #218c74;
        border: none;
        font-size: 20px;
        padding: 10px 30px;
        text-transform: uppercase;
        color: #fff;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}

.shop-gallery {
  z-index: 2;
  width: 800px;
  margin: 20px auto;
  background: #fff;
  box-shadow: 0px 0px 10px #fff;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > h4 {
    text-align: center;
    color: #355c7d;
    margin-bottom: 40px;
  }
}

.shops-item {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .shops-item-meta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;

    h4 {
      margin: 0;

      span {
        border-bottom: 2px solid #ccc;
        padding-bottom: 5px;
        display: inline-block;
        margin-bottom: 15px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.home-btn {
  color: #fff;
  background: $main-color;
  border: none;
  font-size: 16px;
  padding: 5px 15px;
}

.dropleft,
.dropright,
.dropdown {
  .dropdown-toggle-home {
    font-size: 18px;
    padding: 5px 35px 5px 15px;
    background: #fff;
    color: #333;
    position: relative;
    width: 100%;
    min-height: 38px;
    border-color: #ccc;
    text-align: left;
    border-radius: 8px;

    &:before {
      display: none;
    }
    &:after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.4em solid;
      border-right: 0.4em solid transparent;
      border-bottom: 0;
      border-left: 0.4em solid transparent;
      position: absolute;
      right: 8px;
      top: 40%;
    }
  }

  .dashboard-dropdown-toggle {
    &:after {
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }

  &.show {
    .dropdown-toggle-home {
      background: #fff;
      color: #333;
      border-color: #ccc;
    }
  }
}

.card-home.card {
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: none;

  > .card-header {
    // background: linear-gradient(to right, #f7b532, #f38d2a);
    color: #fff;
    height: initial;
    padding: 0.75rem 1.25rem;

    .form-group {
      margin: 0;
    }

    // &.bar-setting {
    //   padding: 20px 45px 120px;
    // }
  }

  &.card-home-main {
    > .card-header {
      padding: 20px 30px;
    }

    // > .card-body {
    //   &.card-body-full {
    //     margin: 30px auto 0;
    //   }
    // }
  }

  .card-body {
    &.card-body-box {
      background: #fff;
      margin: 0 auto 20px;
      width: calc(100%);
      // border-radius: 25px;
      // box-shadow: 0 0 4px #999;
    }

    &.card-body-full {
      margin: auto;
      width: calc(100%);
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }

    &.card-body-normal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &.card-body-full-margin {
      margin: -30px -30px 0 -30px;
      background: #fff;
      &.card-appoitment {
        padding-bottom: 80px;
      }
    }
  }

  // &--marketting {
  //   > .card-body {
  //     margin-top: -100px;
  //   }
  // }

  .card-footer {
    padding: 20px;
    // background: linear-gradient(to right, #f7b532, #f38d2a);
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}

.category-coupon {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .body-category-coupon {
    > .row > div {
      display: flex;
      align-items: stretch;
    }
  }

  .card-footer {
    margin-top: auto;
    background: transparent;
    border: none;
    padding-bottom: 0;
  }
}

.category-coupon-item {
  display: flex;
  background: #fff;
  color: #333;
  border-radius: 15px;
  padding: 10px 15px;
  margin-bottom: 20px;
  border: 2px solid #35a143;
  width: 100%;
  .category-item-meta {
    flex: 1;
    h4,
    p {
      padding-left: 20px;
      position: relative;
      span {
        &:first-child {
          width: 20px;
          font-size: 14px;
          display: inline-block;
          font-weight: normal;
          color: #35a143;
          position: absolute;
          left: 0;
          top: 2px;
        }
      }
    }
    h4 {
      font-size: 15px;
    }
    p {
      font-size: 13px;
      span {
        &:first-child {
          top: 0;
        }
      }
    }
  }
  .category-item-icon {
    display: flex;
    color: #8e7511;
    span {
      background: #ffd31c;
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      border-radius: 50%;
      cursor: pointer;
      &:first-child {
        margin-right: 8px;
      }
    }
  }
}

.card-reviews {
  padding-top: 60px;
  > .card-header {
    background: #fff;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    // position: fixed;
    // top: 83px;
    width: 100%;
    left: 0;
    background: #fff;
    z-index: 2;
    h4 {
      font-size: 17px;
      margin: 0;
      color: #656673;
    }
  }
  > .card-body {
    padding: 30px;
  }
  > .card-footer {
    border: none;
    background: #fff;
  }
}

.table {
  thead {
    th {
      &.sort {
        padding-right: 15px;
        position: relative;
        color: #44adea;
        span {
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

.table-home {
  table-layout: fixed;
  thead {
    tr {
      th {
        border-top: none;
      }
    }
  }

  td {
    vertical-align: middle;
    padding: 5px 12px;
  }
}

.table-reviews {
  thead {
    background: #f5f6f9;
    border: none;
    th {
      border: none;
      color: #565861;
    }
  }
  tbody {
    td {
      vertical-align: middle;
      padding: 5px 12px;
    }
    tr {
      &:nth-child(even) {
        background: #f5f6f9;
      }
    }
  }
}

.toggle-dots {
  width: 40px;
  height: 40px;
  background: #f1efef;
  border: none;
  padding: 5px;
  &:hover {
    background: darken(#f1efef, 20%);
  }
}

.menu-dots {
  .menu-item {
    margin-right: 10px;

    &.menu-item-detail {
      color: $nav-color;
    }
    &.menu-item-extend {
      color: #ff0404;
    }
    &.menu-item-ach {
      color: #6401c8;
      max-width: 16px;
      max-height: 16px;
    }
  }
}

.btn-check-mode {
  color: lighten($nav-color, 5%);
  border-radius: 20px;
  border: 1px solid lighten($nav-color, 5%);
  background: transparent;
  font-weight: 500;
}

.box-content-detail {
  .row {
    margin-bottom: 15px;
    align-items: center;
  }

  h4 {
    text-transform: uppercase;
    color: $main-color;
    font-weight: bold;
    font-size: 20px;
  }

  .card-text {
    color: #ff3510;
    font-weight: 500;
  }

  input {
    border-color: #cccccc;
    border-radius: 8px;
  }
}

.detail-btn {
  color: #fff;
  background: $main-color;
  border: 1px solid $main-color;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 20px;
  min-width: 100px;
  margin: 0 15px;
  &.cancel {
    background: #fff;
    border-color: #999;
    color: #999;
  }

  &.back {
    background: #bbb;
    border-color: #bbb;
  }

  &.stop {
    color: red;
    border-color: red;
    background: transparent;
  }

  &.check {
    color: $nav-color;
    border-color: $nav-color;
    background: transparent;
  }
}

.detail-title {
  font-weight: bold;
  text-transform: uppercase;
}

.expire-date {
  .expire-date-item {
    width: calc(50% - 5px);

    > div > div.react-datepicker__input-container input {
      border-radius: 5px;
      border: 1px solid #ccc;
      font-size: 16px;
      padding: 5px;
    }
  }
  .expire-date-item,
  .expire-date-item > div.react-datepicker-wrapper,
  .expire-date-item > div > div.react-datepicker__input-container,
  .expire-date-item > div > div.react-datepicker__input-container input {
    max-width: 100%;
  }

  span {
    width: 10px;
    text-align: center;
  }
}

.btn-search-detail {
  color: lighten($nav-color, 5%);
  border-radius: 10px;
  border: 1px solid lighten($nav-color, 5%);
  background: transparent;
  font-weight: 500;
}

.modal-content {
  border-radius: 15px;
}

.modal-license-extend {
  max-width: 700px;
}

.modal-license-ach {
  max-width: 1000px;

  .modal-header {
    font-size: 16px;
    font-weight: bold;
  }
}

.custom-input-form {
  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      border-color: $main-color;
      background-color: $main-color;
    }
  }
  label {
    font-weight: bold;
  }

  &.role {
    min-height: 2.5rem;
    .custom-control-input ~ .custom-control-label {
      &:before,
      &:after {
        height: 2rem;
        width: 2rem;
      }
      &:before {
        border-width: 3px;
      }
    }
  }
}

.modal-title-home {
  h5 {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    width: 100%;
    align-items: center;
  }
}

.box-icheckin {
  padding: 20px;
  background: #fff;
  border-radius: 25px;

  .icheckin-meta {
    width: 35%;
    margin-right: 15px;
  }

  .icheckin-content {
    h5 {
      font-size: 20px;
    }

    h4 {
      font-size: 29px;
      font-weight: bold;
      margin: 10px 0;
    }

    p {
      display: flex;
      align-items: center;
      font-size: 17px;

      svg {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
}

.box-activity {
  .item-activity {
    font-size: 18px;
    border-top: 2px solid #ccc;
    padding: 15px 0;
  }
}

.item-time-dashboard {
  min-width: 140px;
  border-radius: 25px;
  color: $main-color;
  border: 1px solid $main-color;
  background: #fff;
  margin: 0 5px;

  &:hover,
  &:focus,
  &:visited,
  &.selected {
    background: $main-color;
    color: #fff;
    box-shadow: none;
  }
}

.form-phone-validate::-webkit-input-placeholder {
  letter-spacing: 3.5px;
}

.form-phone-validate:-moz-placeholder {
  letter-spacing: 3.5px;
}

.form-phone-validate::-moz-placeholder {
  letter-spacing: 3.5px;
}

.form-phone-validate:-ms-input-placeholder {
  letter-spacing: 3.5px;
}

.box-avatar {
  img {
    height: 160px;
    width: 160px;
    object-fit: contain;
  }
}

.form-validate-email {
  position: relative;

  svg {
    position: absolute;
    color: $nav-color;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 18px;
  }
}

.nav-select-region {
  &.nav-tabs {
    justify-content: space-between;
    border: none;
    .nav-item {
      position: relative;
      padding-left: 35px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid #ccc;
        @include transition();
      }

      &:before {
        width: 25px;
        height: 25px;
        left: 0;
      }

      &:after {
        width: 19px;
        height: 19px;
        left: 3px;
      }

      &.is-checked {
        &:after {
          background: $main-color;
        }
      }

      .nav-link {
        padding: 7px 25px;
        border: 1px solid #ccc;
        border-radius: 20px;
        cursor: pointer;
        @include transition();
        &.active {
          cursor: text;
          background: $main-color;
          color: #fff;
        }
      }
    }
  }
}

.tab-select-region {
  h4 {
    border: 2px dashed #ccc;
    font-size: 15px;
    color: #333;
    padding: 10px 0;
    text-align: center;
    font-weight: 500;
    border-radius: 8px;
    margin: 10px 0;
    cursor: pointer;
    @include transition();
    &.is-select {
      border: 2px solid $main-color;
      color: $main-color;
    }
  }
}

.search-dropdown {
  width: 100%;
  .input-search-dropdown {
    padding: 10px 20px;
    input {
      border-radius: 20px;
      background: #eee;
    }
  }
}

.pagination-footer {
  .pagination {
    margin: 0;
    span.dot-pagination {
      display: flex;
      align-items: flex-end;
      line-height: 30px;
      color: #333;
    }
    .page-item {
      margin: 0 3px;

      .page-link {
        background: transparent;
        border: none;
        color: #565861;
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        height: 30px;
        min-width: 30px;

        &:focus,
        &:visited,
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
      &.disabled {
        .page-link {
          color: #fff;
          background: #f4992d;
          border-radius: 5px;
        }
      }
    }
  }
}

.total-footer {
  display: flex;
  align-items: center;
  .dropdown-toggle {
    min-width: 70px;
    padding: 5px 20px 5px 10px;
    border-radius: 18px;
    background: #e3e1ec;
    border: none;
    color: #595c66;
    text-align: left;
    &:after {
      border-top: 0.3em solid;
      border-bottom: 0;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }

  .dropdown-menu {
    min-width: initial;
  }

  > p {
    margin: 0 30px 0 20px;
    font-size: 15px;
    font-weight: 500;
    color: #595c66;
  }
}

@media (max-width: 767px) {
  .group-footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .total-footer {
    margin-top: 20px;
    width: 100%;
  }
}

.email-report {
  display: flex;
  align-items: center;
  svg {
    color: $main-color;
    font-size: 20px;
    margin-right: 10px;
  }
}
.modal-formgroup-note {
  min-width: 1000px;
  textarea {
    resize: none;
    border: 2px solid #ccc;
    border-radius: 10px;

    &:disabled {
      border: 2px solid transparent;
    }
  }

  .btn-group-edit-note {
    display: none;
    line-height: 25px;
    height: 30px;
    .btn-clear-note {
      color: #bbb;
    }

    button,
    button:hover,
    button:focus,
    button:visited,
    button:active {
      font-size: 30px;
      line-height: 25px;
      height: 30px;
      margin-left: 10px;
    }
  }

  .formgroup-note {
    .title-note {
      line-height: 30px;
      margin-bottom: 5px;
    }
    &:hover {
      .btn-group-edit-note {
        display: block;
      }
    }
    .formgroup-btn-note {
      height: 30px;
      margin-top: 10px;
      button {
        color: #000;
        font-weight: bold;
        margin-right: 15px;
        &:last-child {
          color: $main-color;
        }
      }
    }
  }
  button:not(.home-btn),
  button:not(.home-btn):hover,
  button:not(.home-btn):focus,
  button:not(.home-btn):visited,
  button:not(.home-btn):active {
    border: none !important;
    background: none !important;
    padding: 0;
    box-shadow: none !important;
  }
  .close-icon {
    color: #333;
    font-size: 30px;
    line-height: 25px;
    margin-left: auto;
  }
}

.card-role {
  .card-title,
  .row {
    min-height: 2.5rem;
    font-size: 17px;
  }
}

.star-rating-symbol {
  display: inline-flex;
  width: 20px;
  height: 20px;
  margin: 0;
}

.star-rating-small {
  display: inline-flex;
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0;
}

.rating-total {
  background: #fff;
  padding: 40px 40px 30px;
  border-radius: 25px;
  box-shadow: 0 5px 10px #999;
  text-align: center;
  height: 100%;

  @media (max-width: 767px) {
    height: initial;
    margin-bottom: 15px;
  }

  h5 {
    font-size: 40px;
    font-weight: bold;
    color: #0b5b76;
  }

  p {
    font-size: 15px;
    font-weight: bold;
    margin: 15px 0;
  }

  h4 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }
}

.rating-detail {
  height: 100%;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 5px 10px #999;
  padding: 30px 40px;

  .rating-detail-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &:last-child {
      margin: 0;
    }

    > span:first-child {
      margin-right: 15px;
    }

    > span:last-child {
      font-weight: bold;
    }

    &:nth-child(1) {
      .rating-bar span {
        background: #ff6f00;
      }
    }
    &:nth-child(2) {
      .rating-bar span {
        background: #27ae60;
      }
    }
    &:nth-child(3) {
      .rating-bar span {
        background: #7f8c8d;
      }
    }
    &:nth-child(4) {
      .rating-bar span {
        background: #8e44ad;
      }
    }
    &:nth-child(5) {
      .rating-bar span {
        background: #e50019;
      }
    }

    .rating-bar {
      flex: 1;
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        height: 16px;
      }
    }
  }
}

.dashboard-statis {
  background: #fff;
  padding: 20px 0 30px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-shadow: 0 0 5px #ccc;
  width: 100%;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }

  > span {
    width: 100px;
    height: 100px;
    display: inline-flex;
    margin: 0 auto;
    background: #079992;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &.stt-return {
      background: #ff6f00;
    }

    &.imp-feedback {
      background: #0a3d62;
    }

    &.local-feedback {
      background: #9900ff;
    }

    &.yelp-feedback {
      background: #bf2519;
    }

    &.fb-feedback {
      background: #475993;
    }

    &.gg-feedback {
      background: #03b62d;
    }
  }

  p {
    font-size: 15px;
    font-weight: bold;
    margin: 30px 0 10px;
    padding: 0 10px;
  }

  @media (max-width: 991px) {
    > span {
      width: 70px;
      height: 70px;
      font-size: 25px;
    }
    p {
      font-size: 13px;
    }
  }
}

.dashboard-coupon {
  display: flex;
  background: #fff;
  padding: 30px 20px;
  border-radius: 15px;
  align-items: center;
  box-shadow: 0 0 5px #ccc;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }

  &.coupon-sent {
    > span {
      img {
        border-color: #ff6f00;
      }
    }
    .dashboard-coupon-meta {
      color: #ff6f00;
      flex: 1;
    }
  }

  &.coupon-used {
    > span {
      img {
        border-color: #00a24b;
      }
    }
    .dashboard-coupon-meta {
      color: #00a24b;
      flex: 1;
    }
  }

  &.coupon-expire {
    > span {
      img {
        border-color: #000000;
      }
    }
    .dashboard-coupon-meta {
      color: #000000;
      flex: 1;
    }
  }

  > span {
    padding-right: 20px;
    margin-right: 20px;
    position: relative;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      height: 75px;
      width: 2px;
      background: #aaa;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .dashboard-coupon-meta {
    font-weight: bold;

    p {
      font-size: 40px;
      margin: 0 0 10px;
      line-height: 40px;
    }

    span {
      font-size: 17px;
    }
  }
  @media (max-width: 991px) {
    > span {
      padding-right: 10px;
      margin-right: 10px;
      img {
        width: 70px;
        height: 70px;
      }
    }

    .dashboard-coupon-meta {
      p {
        font-size: 25px;
        line-height: 25px;
      }
      span {
        font-size: 13px;
      }
    }
  }
}

.date-picker-right {
  .react-datepicker__triangle {
    left: initial;
    right: 50px;
  }
}

.coupon-item {
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #f38d2a;
  background: #f38d2a;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;

  .coupon-item-meta {
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      height: 300px;
    }
  }

  .coupon-item-content {
    color: #333;
    margin-top: auto;

    h5 {
      text-align: center;
      padding: 15px 0 10px;
      margin: 0;
      cursor: pointer;
      font-size: 17px;
      color: #fff;
      font-weight: bold;
    }
    .coupon-rating-content {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;

      p {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
}

.btn-add {
  border: 1px solid #fff;
  color: #fff;
  background: #0b5b76;
  border-radius: 5px;
  min-width: 120px;
  font-weight: 500;
  text-transform: uppercase;
  min-height: 36px;
}

.btn-back {
  background: none;
  color: #275786;
  border: none;
  padding-left: 30px;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  &.color-inverse {
    color: #f4992d;
  }
  &:before {
    display: inline-block;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.4em solid transparent;
    border-right: 0.5em solid;
    border-bottom: 0.4em solid transparent;
    border-left: 0;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.input-radius {
  padding: 0 20px;
  color: #333;
  border: 1px solid #a7a8bc;
  border-radius: 20px;
  font-weight: 500;
}

textarea {
  &.input-radius {
    border-radius: 15px;
    padding: 10px 15px;
  }
}

.item-rating-coupon {
  width: 45px;
  height: 45px;
  border: 2px solid #ff6f00;
  display: inline-flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  color: #ff6f00;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  @include transition;

  &.selected {
    color: #fff;
    background: #ff6f00;
  }

  &:last-child {
    margin: 0;
  }
}

.row-coupon {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  max-width: 1024px;
  label {
    font-weight: bold;
  }
}

.coupon-date-input {
  border: 2px solid #ff6f00;
  color: #ff6f00;
  border-radius: 5px;
  font-weight: 500;

  & ~ button:after {
    font: normal normal normal 14px/1 FontAwesome;
    background: #ff6f00;
    content: "\f00d";
    padding: 0;
    font-size: 8px;
  }
}

.custom-input-large {
  padding-left: 0;
  line-height: 30px;
  .custom-control-label {
    min-height: 30px;
  }
  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      border-color: #009688;
      background-color: #009688;
    }
  }

  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: 45px;
    &:before {
      width: 30px;
      height: 30px;
      left: 0;
      top: 0;
    }

    &:after {
      background-image: none !important;
      left: 10px;
      top: 4px;
      width: 10px;
      height: 17px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.file-button-none {
  border: 0;
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: 100%;

  &.square {
    width: 170px;
  }

  label {
    border: 1px solid #262626;
    padding: 10px;
    border-radius: 15px;
    width: 100%;
    height: 170px;
    max-width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    #img_upload_id {
      object-fit: contain;
      display: flex;
      max-width: 100%;
      max-height: 100%;
      position: relative;
      width: initial;
    }
    &.is-disable {
      cursor: auto;
    }
  }

  .file-btn-delete {
    font-size: 25px;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    color: #355c7d;
    padding: 0;
    position: absolute;
    background: #fff;
    font-weight: 700;
    line-height: 18px;
    border: none;
    transition: all 0.35s ease-in-out;
    z-index: 2;
    &:focus {
      box-shadow: none;
    }
  }
}

.btn-delete {
  background: $color-disable;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: inline-block;
  padding: 6px 0;
  border: 1px solid $color-disable;
  border-radius: 10px;
  min-width: 150px;
  outline: none;
  @include transition();
  &:hover,
  &:focus,
  &:active,
  &:visited {
    background: darken($color-disable, 10%);
  }
}

.btn-active {
  background: $color-active;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: inline-block;
  padding: 6px 0;
  border: 1px solid $color-active;
  border-radius: 10px;
  min-width: 150px;
  outline: none;
  @include transition();
  &:hover,
  &:focus,
  &:active,
  &:visited {
    background: darken($color-active, 10%);
  }
  &:disabled {
    border-color: gray;
    background: gray;
  }
}

.modal-delete {
  max-width: 600px !important;
  min-width: 600px;

  .modal-content {
    padding: 30px 20px;
    border-radius: 15px;

    .modal-header {
      justify-content: center;
      padding: 0;
      margin-bottom: 40px;
      h5 {
        font-size: 16px;
      }
    }
    .modal-footer {
      padding: 0;
      justify-content: center;

      button {
        line-height: 45px;
        min-width: 150px;
        border: none;
        margin: 0 30px !important;
        border-radius: 10px;
        font-weight: bold;
        box-shadow: 0 3px 5px #aaa;
        color: #fff;

        &:first-child {
          background: #666666;
        }
        &:last-child {
          background: #0b7620;
          &:disabled {
            background: #666666;
          }
        }
      }
    }
  }
}

.modal {
  z-index: 10000;

  .modal-confirm {
    max-width: 350px !important;
    min-width: 300px;
    .modal-content {
      padding: 20px 35px;
      border-radius: 15px;
    }

    .modal-header {
      padding: 0;
      margin-bottom: 10px;

      h5 {
        font-size: 16px;
      }
    }
  }

  .modal-dialog.modal-gallery {
    max-width: calc(100% - 60px) !important;
    min-width: 600px;

    .modal-content {
      padding: 30px 20px;
      border-radius: 15px;

      .modal-header {
        justify-content: center;
        padding: 0;
        margin-bottom: 40px;
        h5 {
          font-size: 16px;
        }
      }
      .modal-footer {
        padding: 0;
        justify-content: center;

        button {
          line-height: 45px;
          min-width: 150px;
          border: none;
          margin: 0 30px !important;
          border-radius: 10px;
          font-weight: bold;
          box-shadow: 0 3px 5px #aaa;
          color: #fff;

          &:first-child {
            background: #666666;
          }
          &:last-child {
            background: #0b7620;
            &:disabled {
              background: #666666;
            }
          }
        }
      }
    }
  }

  .modal-dialog.modal-create {
    max-width: 600px !important;
    min-width: 600px;

    .modal-content {
      border-radius: 15px;
      overflow: hidden;
      .modal-header {
        padding: 0;
        padding: 20px;
        background: #f38d2a;
        color: #fff;
        text-transform: uppercase;
        h5 {
          font-size: 16px;
        }
      }

      .modal-body {
        padding: 30px 20px 0;

        label {
          font-weight: 500;
        }
      }

      .modal-footer {
        padding: 20px 0;
        justify-content: center;

        button {
          line-height: 45px;
          min-width: 150px;
          border: none;
          margin: 0 30px !important;
          border-radius: 10px;
          font-weight: bold;
          box-shadow: 0 3px 5px #aaa;
          color: #fff;

          &:first-child {
            background: #666666;
          }
          &:last-child {
            background: #0b7620;
            &:disabled {
              background: #666666;
            }
          }
        }
      }
    }
  }
}

.staff-item {
  padding: 10px;
  background: #fff;
  border-radius: 15px;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0 0 5px #ccc;

  &:hover {
    .staff-item-info {
      h5 {
        color: $bg-primary;
      }
    }
  }

  .staff-item-meta {
    border-radius: 50%;
    overflow: hidden;
    height: 100px;
    width: 100px;
    margin: 0 auto 30px;
    border: 3px solid $bg-primary;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img.empty {
      max-width: 50px;
    }
  }

  .staff-item-info {
    h5 {
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      color: #594f4f;
      @include transition();
    }
    p {
      margin: 0;
    }
  }
  .staff-group-btn {
    display: flex;
    justify-content: space-between;
    margin: 20px 10px 10px;
    button {
      width: calc(50% - 5px);
      padding-left: 0;
      padding-right: 0;
    }
  }
  .total-reviews {
    font-weight: 500;
    color: #547980;
  }
}

.category-items {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  background: #fff;
  border-radius: 15px;

  > div {
    border-right: 3px solid #eee;
  }

  &:last-child {
    border: none;
  }
  .label-category {
    padding-top: 30px;
    label {
      margin-bottom: 15px;
      position: relative;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
    }

    .group-btn-category {
      margin-bottom: 30px;

      button {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #f38d2a;
        color: #fff;
        margin-right: 10px;
        border: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.btn-add-sv {
          background: #355c7d;
        }

        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  .service-items {
    padding-top: 30px;
  }

  .service-item {
    padding: 10px;
    background: #547980;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    display: flex;
    margin-bottom: 30px;

    flex-wrap: wrap;

    .service-item-meta {
      width: 60px;
      height: 60px;
      display: inline-flex;
      span {
        width: 60px;
        height: 60px;
        border: 3px solid #f59e2d;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        img {
          object-fit: contain;

          &.empty {
            max-width: 35px;
          }
        }
      }
    }
    .service-item-info {
      width: calc(70% - 15px);
      text-align: left;
      color: #fff;
      margin-left: 15px;
      h5 {
        cursor: pointer;
        font-size: 16px;
        @include transition();
      }
    }
  }
}

.page-404 {
  padding: 20px;
  .banner-404 {
    max-width: 100%;
    width: 400px;
    padding: 20px 20px 50px;
    border: 2px dashed #f38d2a;
    border-radius: 20px 20px 0 0;
    background: #fff;
    border-bottom: none;
  }

  .content-404 {
    background: #f38d2a;
    color: #fff;
    text-align: center;
    padding: 20px;
    border: 2px dashed #fff;
    border-top: none;
    border-radius: 0 0 20px 20px;

    h5 {
      margin-bottom: 20px;
    }

    button {
      border: 1px solid #fff;
      background: #fff;
      padding: 0 15px 0 0;
      border-radius: 10px;
      font-weight: 500;
      cursor: pointer;
      span {
        width: 35px;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #355c7d;
        overflow: hidden;
        border-radius: 10px 0 0 10px;
        color: #fff;
        margin-right: 15px;
      }
    }
  }
}

.social-items {
  display: flex;
  align-items: center;
  .social-items-icon {
    width: 40px;
    margin-right: 30px;
  }

  .social-items-input {
    flex: 1;
    input {
      line-height: 45px;
      height: initial;
      padding: 0 20px;
      border-radius: 25px;
      border: 2px solid #ccc;
      width: 100%;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .social-items-del {
    margin-left: 30px;
    span {
      width: 35px;
      height: 35px;
      display: inline-flex;
      background: #f38e2a;
      color: #fff;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
}

.setting-thanks-screen {
  padding: 30px 20px;
  border: 3px dashed #ff6f00;
  font-weight: bold;
  font-size: 18px;
  &:focus {
    box-shadow: none;
  }
}

.title-setting {
  font-weight: bold;
  font-size: 18px;
}

span.btn-add-feedback {
  width: 45px;
  height: 45px;
  margin-left: auto;
  background: #3879ed;
  font-size: 24px;
  color: #fff;
  margin-right: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &--remove {
    margin-right: 0;
    background: #f6453f;
  }
  &--check {
    margin-right: 0;
    background: #47941b;
  }
}

.feedback-header {
  display: flex;
  align-items: center;
  // margin-bottom: 30px;
  width: 100%;
  h4 {
    font-size: 20px;
    color: #2a363b;
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
  }

  button {
    &.dashboard-dropdown-toggle {
      background: #f38d2a;
    }
    &:not(.dashboard-dropdown-toggle):not(.btn-add):not(.dropdown-item) {
      border: none;
      background: none;
      font-size: 17px;
      margin-left: auto;
      color: #f26b38;
      font-weight: bold;
    }
  }
}

.feedback-items {
  margin-bottom: 20px;
  h5 {
    font-size: 17px;
    color: #2a363b;
    margin: 0 0 15px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    button {
      border: none;
      background: none;
      font-size: 15px;
      margin-left: auto;
      color: #f26b38;
      font-weight: bold;
    }
  }
}

.question-items {
  align-items: center;
  margin: 0;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
  label {
    font-weight: 500;
    color: #547980;
  }
  .star-rating-symbol {
    width: 15px;
    height: 15px;
  }

  .btn-questions-edit {
    margin: 0 auto;
    width: 25px;
    height: 25px;
    padding: 0;
    font-size: 13px;
    background: #f38e2a;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
}

.gallery-item {
  margin-bottom: 30px;
  border: 2px solid transparent;
  border-radius: 15px;
  overflow: hidden;

  &.selected {
    border: 2px solid $bg-primary;
  }
}

.coupon-gallery-container {
  left: -10000px;
  position: absolute;
}
.coupon-image-gallery {
  position: relative;
  width: 670px;
  border-radius: 25px;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }

  .image-gallery-meta {
    position: absolute;
    top: 60px;
    left: 60px;
    color: #fff;
    span {
      font-size: 40px;
      margin-top: 20px;
      display: inline-block;
    }
    h4 {
      font-size: 55px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      max-width: 60%;
    }
  }
}

.btn-gallery-coupon {
  font-weight: bold;
  background: #0b5b76;
  color: #fff;
  border: none;
  height: initial;
  font-size: 14px;
}

.loading-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border: 1px solid #262626;
  border-radius: 15px;
  img {
    max-width: 100px;
  }
}

.error.name-error {
  font-size: 11px;
  color: red;
  font-weight: 500;
}

@media (max-width: 1199px) {
  .layout-home {
    .layout-body {
      padding-left: 0;
    }
  }
}

.error_msg {
  text-align: center;
  margin-bottom: 10px;
  color: red;
}

.qr-banner-share-container {
  position: fixed;
  left: -10000px;
  top: 0;
  display: inline-block;
}

#qr-banner-share {
  width: 794px;
  height: 1123px;
  max-width: 100%;
  background-size: cover !important;
  background-position: center !important;

  .qr-banner-share-top {
    padding: 50px 65px 0;
    h4 {
      font-family: "E111Viva", sans-serif;
      font-size: 65px;
      text-align: center;
      margin-bottom: 40px;
    }

    .qr-description {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 50px;

      p {
        font-size: 28px;
        text-align: right;
        color: #402412;
      }
      span {
        margin-left: 15px;
        max-height: 140px;
        display: inline-flex;

        img {
          max-height: 100%;
          object-fit: contain;
        }
      }
    }

    .qr-scan-text {
      font-size: 30px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #69441a;
      margin-bottom: 20px;
      span {
        font-size: 43px;
        margin-right: 15px;
        color: #69441a;
      }
    }

    .qr-banner {
      text-align: center;
      margin-bottom: 40px;
    }

    .qr-content {
      font-size: 26px;
      text-align: center;
      max-width: 75%;
      margin: 0 auto;
      color: #070706;
    }
  }

  .qr-banner-share-bottom {
    .qr-info {
      margin: 35px 0;
      text-align: right;
      p {
        display: inline-block;
        width: 50%;
        font-size: 32px;
        font-weight: 500;
        text-align: center;
        padding: 5px 0;
        background: linear-gradient(to right, #ae723a, transparent);
        color: #fff;
        position: relative;
        height: 60px;
        &:before {
          content: "";
          position: absolute;
          right: 100%;
          height: 100%;
          width: 20px;
          top: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 60px 45px;
          border-color: transparent transparent #b1763f transparent;
        }
      }
    }

    .qr-end {
      font-size: 20px;
      text-align: center;
      color: #2e312f;
      text-transform: uppercase;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: calc(44% - 60px);
        height: 2px;
        background: #333;
        top: 50%;
      }
      &:before {
        left: 5%;
      }
      &:after {
        right: 5%;
      }
    }
  }
}

#qr-banner-share-mini {
  width: 600px;
  height: 900px;
  max-width: 100%;
  background-size: cover !important;
  background-position: center !important;

  .qr-banner-share-top {
    padding: 30px 70px 0;
    h4 {
      font-family: "E111Viva", sans-serif;
      font-size: 55px;
      text-align: center;
      margin-bottom: 30px;
    }

    .qr-description {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 40px;

      p {
        font-size: 20px;
        text-align: right;
        color: #402412;
      }
      span {
        margin-left: 15px;
        display: inline-flex;
        max-height: 100px;
        max-width: 100px;
        img {
          max-height: 100%;
          object-fit: contain;
        }
      }
    }

    .qr-scan-text {
      font-size: 25px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #69441a;
      margin: 0 0 10px;
      span {
        font-size: 40px;
        margin-right: 15px;
        color: #69441a;
      }
    }

    .qr-banner {
      text-align: center;
      margin-bottom: 25px;
      max-height: 230px;
      display: flex;
      justify-content: center;
      img {
        object-fit: contain;
      }
    }

    .qr-content {
      font-size: 20px;
      text-align: center;
      max-width: 75%;
      margin: 0 auto;
      color: #070706;
    }
  }

  .qr-banner-share-bottom {
    .qr-info {
      margin: 25px 0;
      text-align: right;
      p {
        display: inline-block;
        width: 42%;
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        padding: 5px 0;
        background: linear-gradient(to right, #ae723a, transparent);
        color: #fff;
        position: relative;
        height: 50px;
        &:before {
          content: "";
          position: absolute;
          right: 100%;
          height: 100%;
          width: 20px;
          top: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 50px 35px;
          border-color: transparent transparent #af733b transparent;
        }
      }
    }

    .qr-end {
      font-size: 20px;
      text-align: center;
      color: #2e312f;
      text-transform: uppercase;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: calc(44% - 60px);
        height: 2px;
        background: #333;
        top: 50%;
      }
      &:before {
        left: 5%;
      }
      &:after {
        right: 5%;
      }
    }
  }
}

#qr-banner-signin {
  width: 600px;
  height: 600px;
  padding: 50px;
  background-position: center !important;
  background-size: 100% !important;
  h4 {
    font-size: 55px;
    text-align: center;
    font-family: "E111Viva", sans-serif;
    margin-bottom: 30px;
  }
  .qr-banner {
    height: 240px;
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    img {
      object-fit: contain;
    }
  }
  .qr-text-checkin {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    font-family: "Calendas_Plus";
    color: #6a451a;
  }
  .qr-scan-text {
    font-size: 26px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-family: "Calendas_Plus";
    color: #6a451a;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 38px;
      margin-right: 15px;
    }
  }
}

.link-qr-code {
  padding: 5px 20px 5px 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #725933;
  border: 2px solid #f6a72f;
  background: #fff;
  font-weight: bold;
  margin: 0 20px;

  span {
    &:first-child {
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background: #f6a72f;
      border-radius: 5px;
      color: #fff;
    }
  }
  &:hover {
    color: #725933;
  }
}

.btn-qr-code {
  padding: 5px 20px;
  border-radius: 10px;
  border: 2px solid transparent;
  background: #f6a72f;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.35s ease-in-out;
  outline: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: darken(#f6a72f, 30%);
  }
  span {
    min-height: 30px;
    &:first-child {
      display: inline-block;

      margin-right: 0;
      transition: all 0.25s ease-in-out;
      img {
        max-width: 0px;
      }
      &.is-loading {
        img {
          max-width: 30px;
        }
        margin-right: 15px;
      }
    }

    &:last-child {
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

.loading-popup {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999999999999;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-select-service {
  width: 100%;
}

.dropdown-select-service {
  width: 100%;
  transform: translate(0, 10px);
  max-height: 250px;
  overflow: auto;
  background: #d5d5d5;
  padding: 10px 20px;
  button {
    padding: 10px;
    border-bottom: 1px solid #e9e9e9;
    font-weight: bold;
    white-space: normal;
    &:hover {
      background: #d5d5d5;
      color: #333;
      box-shadow: none;
      outline: none;
    }
    &:last-child {
      border: none;
    }
  }
}

.marketting-info {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 #ccc;
  display: flex;
  flex-direction: column;
  width: 100%;

  .marketting-info__info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .marketting-info__info__meta {
      width: 30%;
    }
    .marketting-info__info__content {
      flex: 1;

      h5 {
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        align-items: center;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        button {
          font-size: 13px;
          padding: 5px 10px;
          background: #fff6e5;
          color: #ffa900;
          font-weight: bold;
          border: 2px solid #ffa900;
          border-radius: 15px;
        }
      }
    }
  }

  .marketting-info__detail {
    padding: 15px 0 0;
    display: flex;
    border-top: 1px solid #ccc;
    margin-top: auto;
    align-items: center;

    > button {
      margin-left: auto;
      border: 1px solid #ccc;
      padding: 5px 15px;
      border-radius: 5px;
      background: #fff;
    }
  }
}

.campain {
  display: flex;

  .campain-arrow {
    width: 50px;
    font-size: 35px;
    text-align: center;
    color: #97d670;
    span {
      display: inline-block;
    }
    &.arrow-45 {
      color: #f2ca59;
      span {
        transform: rotate(45deg);
      }
    }
  }
  .campain-content {
    .campain-des {
      font-weight: 500;
    }
  }
}

.table-marketting {
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;

  .card.table-list-marketting {
    .card-header {
      padding: 0;
      border: none;
      // background: linear-gradient(to bottom, #fff, #fff, #f7f7f7 70%, #f9f9fa);
      flex-wrap: wrap;
      // height: initial;
      > h4 {
        padding: 15px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        > span {
          display: flex;
          align-items: center;
        }
      }
      .header-tabs {
        padding: 0 20px;
        position: relative;
        margin: 0;
        width: 100%;
        &:before {
          content: "";
          position: absolute;
          bottom: 1px;
          width: 100%;
          height: 1px;
          background: #ccc;
          left: 0;
          z-index: 0;
        }
        li {
          display: inline-block;
          padding: 15px 15px;
          font-size: 17px;
          font-weight: 500;
          color: #8f8f8f;
          min-width: 100px;
          text-align: center;
          transition: all 0.35s ease-in-out;
          border: 1px solid transparent;
          border-radius: 10px 10px 0 0;
          border-bottom: none;
          cursor: pointer;
          &.active {
            color: #333333;
            border: 1px solid #ccc;
            border-bottom: none;
            background: #fff;
            position: relative;
          }
        }
      }
    }
    .card-body {
      background: #fff;

      .table-marketting-content {
        border: 1px solid #ccc;
        border-radius: 10px;

        table {
          thead {
            border-bottom: 1px solid #ccc;
          }
        }
      }
    }
    .card-footer {
      border: none;
      background: #fff;

      .pagination-footer {
        .pagination {
          .page-item {
            margin: 0 3px;

            .page-link {
              border: none;
              color: #565861;
              font-size: 14px;
              font-weight: 500;
              padding: 0;
              height: 30px;
              min-width: 30px;
              border: none;
              &:focus,
              &:visited,
              &:active {
                outline: none;
                box-shadow: none;
              }
            }
            &.disabled {
              .page-link {
                background: transparent;
                border-bottom: 2px solid #333;
                border-radius: 0;
              }
              &.previous-link,
              &.next-link {
                .page-link {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-create-marketting {
  max-width: calc(100% - 60px) !important;

  h4 {
    font-size: 17px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    min-height: 38px;
    button {
      margin-left: auto;
      color: #318ef5;
      border: none;
      background: none;
      border-radius: 10px;
      font-weight: 500;
      font-size: 15px;
      padding: 10px 15px;
      @include transition();
      &:hover {
        background: #e1effe;
      }
    }
  }
}

.btn-campain {
  font-size: 14px;
  color: white;
  background: #0091ff;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid transparent;
  min-width: 100px;
  @include transition();

  &--cancel {
    color: #666666;
    border: 1px solid #ccccd5;
    background: #fff;
    margin-right: 20px;
  }

  &:disabled {
    background: #ccc;
    color: #fff;
  }
}

.form-campain {
  padding: 15px 0;
  font-size: 14px;

  .dropdown-full {
    .dropdown {
      > button {
        width: 100%;
        font-size: 14px;
        text-align: left;
        padding-right: 20px;
        background: #fff;
        color: #9a9a9a;
        font-weight: 500;
        border-color: #9a9a9a;
        &.isSelected {
          background: #0a4277;
          color: #fff;
        }

        &:after {
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translate(0, -50%);
        }

        &:disabled {
          background: #eeeef1;
        }
      }

      .dropdown-menu {
        width: 100%;
        max-height: 250px;
        box-shadow: 0 0 5px 0 #ccc;
        overflow: auto;
        button {
          width: 100%;
          line-height: initial;
          border-bottom: 1px solid #ccc;
          &:last-child {
            border: none;
          }
        }
      }
    }

    input {
      line-height: 33px;
      padding-top: 0;
      padding-bottom: 0;
      height: initial;
      border-color: #b3b3b3;
      font-size: 14px;
      color: #9a9ca7;
      font-weight: 500;
    }

    .group-dropdown-full {
      position: relative;

      > span {
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translate(0, -50%);
        z-index: 11;
        color: #101010;
      }
      .react-datepicker-wrapper {
        width: 100%;
        input {
          padding-right: 15px;
        }
      }
    }
  }
}

.form-campain-content {
  padding: 15px 0;
  font-size: 14px;

  .quote-icon {
    display: inline-block;
    position: absolute;
    font-size: 35px;
    color: #777;
    top: -30px;
    left: 0px;

    &.enable {
      color: #ccc;
    }
  }

  .content-message {
    width: 100%;
    padding: 20px 15px;
    background: #d5d5d5;
    border: none;
    &:focus {
      outline: none;
    }
    &:disabled {
      background: #ccc;
    }
  }
}

.modal-new-coupon {
  max-width: calc(100% - 300px);
  h4 {
    font-size: 17px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    min-height: 38px;
  }
}

.image-upload {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  .image-drop {
    position: relative;
    object-fit: cover;
    object-position: center center;
    border: 3px dashed rgb(227, 227, 232);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    flex-wrap: wrap;
    cursor: pointer;

    > span {
      display: flex;
      width: 100%;
      font-size: 35px;
      text-align: center;
      justify-content: center;
      margin-bottom: 15px;
      color: #aaa;
    }
    > p {
      color: #aaa;
      font-weight: 500;
      font-size: 17px;
    }

    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      object-position: center;
    }
  }

  input {
    width: 100%;
  }
}

.status-report {
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid;
  border-radius: 15px;
  font-weight: 500;
  &--active {
    border-color: #abed87;
    color: #47941b;
    background: #dcf7ce;
  }
  &--reject {
    border-color: #fb9b93;
    color: #c02014;
    background: #fee4e2;
  }
  &--used {
    border-color: #ffdd7a;
    color: #b68b16;
    background: #fff1cc;
  }
  &--expired {
    border-color: #e3e3e8;
    color: #9f9fb0;
    background: #f9f9fa;
  }
}

.header-table-content {
  display: flex;
  margin-bottom: 25px;
  border: 1px solid #e3e3e8;
  background: #f9f9fa;
  border-radius: 10px;

  .item-content-header {
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 2px solid transparent;
    @include transition();
    span {
      font-size: 25px;
      margin-bottom: 5px;
      font-weight: 500;
      display: inline-block;
    }
    p {
      font-size: 14px;
      text-transform: uppercase;
      color: #a8a8a8;
      font-weight: 500;
    }
    &.selected {
      border: 2px solid #1f93f6;

      p {
        color: #1f84f4;
      }
    }
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
    &:nth-child(2) {
      span {
        color: #429116;
      }
    }
    &:nth-child(3) {
      span {
        color: #ffb900;
      }
    }
    &:nth-child(4) {
      span {
        color: #f72d1d;
      }
    }
    &:nth-child(5) {
      span {
        color: #1abcd9;
      }
    }
  }
}

.download-customer {
  background: #429116;
  color: #fff;
  display: inline-flex;
  width: 35px;
  height: 35px;
  border: 1px solid #429116;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  &.disabled {
    background: #aaa;
    border-color: #aaa;
  }
}

.form-download {
  position: relative;
  span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    display: inline-block;
    padding: 10px;
  }
}

.coupon-marketting {
  border: 2px solid transparent;
  border-radius: 15px;
  overflow: hidden;
  &.selected {
    border-color: #429116;
  }
}

.group-btn-campain {
  display: flex;
  margin-top: 10px;
  button {
    padding: 5px 10px;
    margin-right: 5px;
    border: 2px solid #007bff;
    background: #fff;
    border-radius: 5px;
    color: #007bff;
    font-weight: 500;
  }
}

.gallery-category-item {
  height: 150px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  overflow: hidden;
  &:before {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    top: 0;
    left: 0;
    content: "";
    outline: 1px solid #fff;
    outline-offset: -2px;
  }
  h4 {
    font-size: 15px;
    color: #fff;
    position: relative;
  }
}

.gallery-service-item {
  margin-bottom: 30px;
  display: flex;
  border: 2px solid #f26b38;
  border-radius: 10px;
  overflow: hidden;
  height: 170px;
  position: relative;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(252, 145, 58, 0.7);
    display: none;
  }
  &.selected {
    &::before {
      display: block;
    }
  }
}

.row-stretch > div {
  display: flex;
  align-items: stretch;
}

.line-marker {
  background-color: #f26c6c !important;
  width: 2px !important;
  z-index: 2222;
  span {
    background: #f26c6c;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 1px 5px;
    border-radius: 10px;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    white-space: nowrap;
  }
  &--furture,
  &--past {
    background-color: #aaa !important;
    span {
      background: #aaa;
    }
  }
  .area-past {
    position: absolute;
    right: 100%;
    top: 0;
    height: 100%;
    background: rgba(153, 153, 153, 0.1);
  }
  .area-furture {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    background: rgba(153, 153, 153, 0.1);
  }
}

.sticky {
  position: sticky;
  z-index: 111;
  top: 0;
  background: transparent !important;
}

.card-body {
  .react-calendar-timeline {
    width: 100% !important;
    // &.isMobile {
    //   .rct-scroll {
    //     touch-action: auto;
    //   }
    // }
    .rct-header-root {
      border-bottom: none;
      position: fixed;
      z-index: 112;
      margin-top: -2px;
    }
    .rct-calendar-header {
      border: none;
    }
    .rct-sidebar {
      padding-bottom: 15px;
      .rct-sidebar-row {
        border-bottom: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &.rct-sidebar-row-odd {
          background: transparent;
        }
      }
    }

    .rct-outer {
      margin-top: 30px;
    }
    // .rct-scroll {
    //   padding-top: 30px;
    // }
    .rct-horizontal-lines {
      .rct-hl-odd {
        background: transparent;
        border-bottom-style: dashed;
      }
      .rct-hl-even {
        border-bottom-style: dashed;
      }
    }
    .rct-vertical-lines .rct-vl {
      border-left-style: dashed;
      &.rct-day-6,
      &.rct-day-0 {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.rct-item {
  background: transparent !important;
  border: none !important;
}

@mixin text-ellipsis {
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-timeline {
  background: #fff;
  border-radius: 5px 0 5px 5px;
  color: #333;
  line-height: initial;
  box-shadow: 0 0 5px #ccc;
  // overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 10px;
  border-bottom: 2px solid #8d8d8d;
  margin-top: 21px;
  position: relative;
  height: 78px;
  &__info {
    align-items: center;
    font-weight: 500;
    color: #8d8d8d;
    @include text-ellipsis;
    > i {
      margin-right: 3px;
    }
    b {
      margin-left: auto;
      padding: 1px 10px;
      border-radius: 15px;
      background: #e9e9e9;
      font-size: 12px;
    }
  }
  h5 {
    margin: 5px 0;
    font-size: 14px;
    font-weight: bold;
    @include text-ellipsis;
  }
  p {
    @include text-ellipsis;
  }

  &.checkin {
    border-bottom-color: #45b027;
    .item-timeline__info {
      color: #45b027;
      i,
      span {
        color: #45b027;
        text-transform: uppercase;
      }
    }
  }
  &.comming {
    border-bottom-color: #0091ff;
    .item-timeline__info {
      color: #0091ff;
      i,
      span {
        color: #0091ff;
        text-transform: uppercase;
      }
    }
  }
  &.comming-anybody {
    border-bottom-color: #febb52;
    .item-timeline__info {
      color: #febb52;
      i,
      span {
        color: #febb52;
        text-transform: uppercase;
      }
    }
  }
  &.arrived {
    .item-timeline__info {
      color: #81da67;
      b {
        background: #81da67;
        color: #333;
      }
    }
  }
  &.not-comming {
    border-bottom-color: #eb0606;
    .item-timeline__info {
      i,
      span {
        color: #eb0606;
      }
    }
  }
  &.finished {
    .item-timeline__info {
      i,
      span {
      }
    }
  }
  .icon-type {
    position: absolute;
    bottom: 100%;
    background: #fff;
    z-index: 22;
    right: 4px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0px -1px 2px #ccc;
    text-align: center;
    font-weight: bold;
    padding: 2px 7px;
    font-size: 11px;
    // &.online {
    //   color: #73a839;
    // }
  }
}

.custom-group {
  line-height: initial;
  text-align: center;
  font-weight: 500;
  span:not(.staff-edit) {
    width: 50px;
    height: 50px;
    display: flex;
    color: #fff;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 5px;
    border: 2px solid #007bff;
    overflow: hidden;
    img {
      object-fit: cover;
    }
  }

  &--staff {
    align-items: flex-start;
    text-align: left;
    padding: 0;
    padding: 7px 0;
    span {
      &:not(.staff-edit) {
        display: inline-flex;
        margin: 0 15px 0 0;
        font-size: 12px;
        width: 30px;
        height: 30px;
      }
    }
  }
  &--edit {
    display: flex !important;
    background: red;
    p {
      white-space: initial;
      flex: 1;
    }
    span {
      &.staff-edit {
        font-size: 18px;
        margin-left: auto;
        color: #136709;
        i {
          margin-left: 10px;
        }
      }
    }
  }
}

.navtab-home {
  padding: 15px 25px 0;
  background: #f9f9fa;
  position: relative;
  z-index: 22;
  .nav-item {
    min-width: 150px;
    text-align: center;
    .nav-link {
      color: #8f8f8f;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 8px 8px 0 0;
      padding: 10px;
      &.active {
        color: #000;
      }
    }
  }
  > button {
    position: absolute;
    right: 55px;
    top: 50%;
    transform: translate(0, -50%);
  }
  > .dropdown {
    position: absolute;
    right: 55px;
    top: 50%;
    transform: translate(0, -50%);
    > button {
      background: #1f84f4;
    }
    .dropdown-menu {
      background: #fff;
      transform: none !important;
      right: 0 !important;
      top: calc(100% + 5px) !important;
      left: initial !important;
      padding: 10px;
      border-radius: 10px;

      > .dropdown-item {
        border-radius: 8px;
        font-weight: 500;
        color: #666666;
        &:hover {
          background: #eeeef1;
          color: #333333;
        }
      }
    }
  }
}

.btn-plus {
  font-weight: 500;
  background: #007bff;
  color: #fff;
  cursor: pointer;
  height: 35px;
  width: 35px;
  margin-left: 15px;
  border-radius: 50%;
  border: none;
}

.form-custom {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  &:focus-within,
  &.form-focus {
    > span:first-child {
      font-size: 11px;
      top: 8px;
    }
    .dropdown-service {
      > button {
        padding-top: 16px;
      }
    }
  }

  &--filter {
    min-height: 35px;
    margin-bottom: 0;
    border-radius: 15px;
    background: #f0f0f0;
  }

  > span {
    &:first-child {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translate(0, -50%);
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
      color: #8f8f8f;
      z-index: 0;
      b {
        color: #f84031;
      }
    }
  }
  &.disabled {
    background: rgba(204, 204, 204, 0.5);
  }
  button {
    box-shadow: none !important;
    outline: none;
  }
  input:not([type="checkbox"]) {
    line-height: initial;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    height: initial;
    margin: auto 0 2px;
    font-size: 15px;
    color: #333333;
    background: transparent;
    &:hover,
    &:focus,
    &:visited {
      box-shadow: none;
      outline: none;
    }
  }
  .react-datepicker-wrapper {
    margin: 0 0 2px;
    line-height: initial;
    flex-grow: 1;
    input {
      margin: 0;
      padding: 16px 0.75rem 0;
      width: 100%;
    }
  }
  .react-tagsinput {
    color: #333;
    > span {
      display: flex;
      flex-wrap: wrap;
    }
    .react-tagsinput-tag {
      padding: 0 0 0 5px;
      background: #e1effe;
      margin-right: 5px;
      display: inline-flex;
      color: #3e3f40;
      line-height: initial;
      font-size: 12px;
      align-items: center;
      font-weight: 500;
      border-radius: 5px;
      margin-bottom: 5px;
      a {
        margin: 0 5px;
        &:before {
          content: "✕";
          font-weight: 500;
        }
      }
    }
  }

  .react-datepicker-popper {
    z-index: 2;
  }
}

.dropdown-service {
  // margin-top: auto;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  > button {
    padding-top: 20px;
    padding-bottom: 0;
    height: 100%;
    width: 100%;
    text-align: left;
    flex-grow: 1;
    padding-top: 16px;
    background-color: transparent !important;
  }
  &--filter {
    min-width: 200px;
    > button {
      padding-top: 0 !important;
    }
    .react-tagsinput .react-tagsinput-tag {
      margin-bottom: 2px;
      margin-top: 2px;
      background: #f4992d;
      color: #fff;
    }
  }
  &--date {
    .dropdown-menu {
      min-width: initial;

      button {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }
  }
  &--appoitment {
    .dropdown-menu {
      border-top: 10px solid #fff;
      border-bottom: 10px solid #fff;
      padding: 0 15px !important;
    }
    .dropdown-service-group {
      background: #fff;
      padding: 0 15px;

      margin: 0;
      > input {
        position: sticky;
        top: 0;
        left: 0;
        margin-bottom: 10px;
        width: 100%;
        border-radius: 5px;
        box-shadow: 0 0 2px #007bff;
        border: 1px solid #007bff;
        padding: 8px 15px;
        background: #fff;
        z-index: 2222;
      }

      .btn-category-service {
        border: none;
        font-weight: bold;
        padding: 0;
        width: 100%;
        text-align: left;
        color: #007bff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          font-size: 20px;
        }
      }

      button {
        &:hover,
        &:focus,
        &:visited {
          background: transparent;
          color: #333;
        }
      }
    }
  }
  .dropdown-menu {
    padding: 10px 15px;
    width: 100%;
    top: calc(100% + 5px) !important;
    transform: none !important;
    box-shadow: 0 0 7px #ddd;
    max-height: 200px;
    overflow: auto;
    min-width: initial;
    @media (max-width: 991.98px) {
      position: absolute !important;
      top: calc(100% + 5px) !important;
      left: 0 !important;
      width: 100% !important;
    }
    > button {
      position: relative;
      align-items: center;
      &:focus,
      &:hover {
        background: transparent;
        color: #999;
      }
    }
  }
  > button {
    color: #333;
    font-weight: 500;
    &:hover,
    &:focus,
    &:visited {
      color: #333;
    }
  }
}

.modal-appoitment {
  max-width: 650px;
  min-width: 500px;
  @media (min-width: 576px) {
    margin: 1.75rem auto;
    margin: 1.75rem auto 3.5rem;
  }
  h4 {
    font-size: 20px;
    margin: 20px 0 15px;
    &:first-child {
      margin-top: 0;
    }
  }
  .modal-header {
    h5 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: #0069d9;
        font-size: 15px;
        cursor: pointer;
        i {
          margin-right: 5px;
          font-size: 18px;
        }
      }
    }
  }
  .modal-footer {
    button {
      min-width: 100px;
    }
  }

  .row-group-select {
    input {
      height: 35px;
      width: 50px;
    }
    label {
      white-space: nowrap;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &--staff {
    min-width: 400px;

    .modal-header {
      background: #0c4378;

      h5 {
        margin: 0;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        justify-content: start;
        span {
          font-size: 14px;
          color: #fff;
          margin: 0 5px 0 0;
        }
      }
    }
    .staff-schedule-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 10px;
      margin-bottom: 10px;
      &:last-child {
        border: none;
        padding: 0;
        margin: 0;
      }
      &__meta {
        flex: 0 0 auto;
        width: 80px;
        margin-right: 20px;
      }
      &__content {
        flex: 1;
        width: calc(100% - 100px);
        h5 {
          font-weight: 700;
          font-size: 16px;
        }
        p {
          margin: 0;

          &.time-service {
            &.checkin {
              color: #45b027;
            }
            &.comming {
              color: #0091ff;
            }
            &.not-comming {
              color: #ff6043;
            }
          }
        }
      }
    }
  }
	.appointment-input {
			height: 50px !important;
			font-weight: 600!important;
			font-size: 18px!important;
			.react-tagsinput-tag {
				font-size: 18px;
			}
	}
	textarea.form-control {
		height: auto!important;
	}
}

.header {
  position: fixed;
  z-index: 5000;
  width: 100%;
  background: white;
  padding-right: 100px;

  .header-schedule {
    background: transparent;
    display: flex;
    align-items: flex-end;
    .group-day {
      display: flex;
      text-align: center;
      justify-content: space-between;
      flex: 1;
      margin-right: 25px;
      > div {
        &.isSelected {
          p:last-child {
            background: #007bff;
            color: #fff;
          }
        }
        p {
          cursor: pointer;
          &:first-child {
            margin-bottom: 10px;
          }
          &:last-child {
            border-radius: 50%;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            margin: 0;
          }
        }
      }
    }
    .react-datepicker-popper {
      z-index: 222;
    }
    .react-datepicker {
      &-wrapper {
        height: 35px;
        display: flex;
        align-items: center;
      }
      &__input-container {
        input {
          padding: 5px;
          text-align: center;
          box-shadow: 0 0 2px #32a5e7;
          border-radius: 5px;
          border: 1px solid #32a5e7;
          font-weight: 500;
        }
        &:before {
          content: "\f073";
          font: normal normal normal 14px/1 FontAwesome;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translate(0, -50%);
          color: #33a5e7;
        }
      }
    }
  }
}

.body-calendar {
  margin-top: 200px;
  padding-left: 10px;
}


.btn-zoom {
  margin-left: 15px;
  button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #007bff;
    background: #fff;
    color: #007bff;
    margin: 0 5px;
    font-size: 17px;
    padding: 0;
  }
}

.fullscreen-enabled {
  padding: 4rem 3rem 3rem;
  background: #111111;
  .header{
    position: relative;
    background: transparent;
  }

  .body-calendar {
    margin-top: -10px;
    .rct-header-root {
      border-bottom: none;
      position: relative;
      z-index: 112;
      margin-top: 0;
    }
  }
  
}

.modal-custom-group {
  position: fixed;
  z-index: 11111;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-custom-dialog {
    background: #fff;
    border-radius: 20px;
    overflow: auto;
    max-height: calc(100vh - 20px);
    margin: 20px 0;

    .modal-content {
      border-radius: 0;
    }
  }
}

.group-customer-select {
  margin-right: 30px;
  button {
    min-width: 150px;
  }
}

.img-circle {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
  height: 100px !important;
  border-radius: 50%;
  object-fit: contain;
}

.input-group {
  margin-top: 10px;
}

.service-filterbar {
  h4 {
    font-size: 15px;
  }
}

.error-message {
  color: #bf1650;
  margin: 0;
  &:before {
    display: inline;
    content: "* ";
  }
}

.balance_amount {
  font-size: 14px;
  color: black;
  font-weight: bold;
}

.value {
  color: blue;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  left: initial;
  right: 50px;
  margin-left: 0;
  margin-right: -8px;
}

.header-sticky-timeline {
  line-height: 30px;
  border-left: 1px dashed transparent;
  background: #fff;
  cursor: pointer;
  &__item {
    transform: translate(-50%, 0);
    color: #a6a6a6;
    font-size: 13px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 400;
    &.bold {
      font-weight: bold;
      color: #333;
    }
  }
}

.review-staff {
  max-width: 600px !important;
}

.modal-create {
  @media (max-width: 500px) {
    .modal-content {
      height: initial;
      max-width: 400px !important;
    }
  }

  @media (max-width: 400px) {
    .modal-content {
      height: initial;
      max-width: 350px !important;
    }
  }
}

// Appointment: modal choose staff
.select-staff-item {
	border: 1px solid #ebe9e9;
	display: flex;
	align-items: center;
	cursor: pointer;
	box-shadow: 0 0 5px #ccc;
}

.select-staff-item .logo-staff {
	margin-right: 15px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
	border: 3px solid #f59e2d;
    overflow: hidden;
	
}

.select-staff-item .logo-staff span {
	font-size: 35px;
	font-weight: 600;
	color: #fff;
}
.select-staff-item  .info-staff {
	width: 30%;
	word-break: break-all;

}

.select-staff-item  .info-staff p {
	font-size: 20px;
    margin: 0 0 10px;
    line-height: 40px;
}

.select-staff-item  .info-staff span {
	font-size: 17px;
	background-color: #a0d0e582;
    text-align: center;
    border-radius: 4px;
    color: #1d7aeed9;
    width: 70px;
    line-height: 23px;
	display: block;
}

@media (max-width: 991px) {
	.select-staff-item .logo-staff {
		width: 70px;
		height: 70px;
	}
	.select-staff-item .logo-staff span {
		font-size: 20px;
	}
	.select-staff-item  .info-staff p {
		font-size: 17px;
		line-height: 25px;
	}
	.select-staff-item  .info-staff span {
		font-size: 13px;
	}
}

#form-search-staff .fa-search {
	position: absolute;
	top: 7px;
	left: 28px;
	color: #49494b;
	font-size: 22px;
}

#available-time-list {
	margin-left: 10px;
	border-left: 1px solid;
	padding-left: 10px;
	display: flex;
	flex-flow: wrap;
	width: 60%;
}

#available-time-list .time-item {
	width: 100px;
	height: 40px;
	line-height: 28px;
	text-align: center;
	padding: 5px;
	margin: 5px;
	border: 1px solid #000;
	border-radius: 10px;
	color: #000;
	font-size: 17px;
	font-weight: 600;
	cursor: pointer;
}

// Appointment: modal choose service
.modal-add-service #other {
	color: #000000;
	font-size: 20px;
	font-weight: 500;
}

.modal-add-service .category-item {
	justify-content: space-between;
	padding: 5px;
	border-bottom: 1px solid #c8c6c65e;
	font-size: 25px;
	font-weight: 600;
	cursor: pointer;
	background-color: #033c73;
	color: #fff;
	line-height: 45px;
}

.modal-add-service .category-item:nth-child(1) {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.modal-add-service .service-list {
	width: 100%
}

.modal-add-service .add-service-item {
	justify-content: space-between;
	padding: 18px;
	border-bottom: 1px solid #c8c6c65e;
	font-size: 18px;
	font-weight: 700;
	color: #5a5a5a;
	cursor: pointer;
}

.modal-add-service .add-service-item:hover, .modal-add-service .add-service-item:checked, .modal-add-service .selected-item {
	background-color: #e7e7e7;
}

.modal-add-service .add-service-item:first-child {
	border-top: 1px solid #c8c6c65e;
}

.modal-add-service #category-tabs .nav-link {
	font-size: 20px;
	font-weight: 600;
}

.modal-add-service .nav-tabs .nav-link.active {
	background-color: #2B94FB!important;
	color: #fff!important;
}

// pop customer
.edit-customer-btn {
	width: 100%;
	margin-top: 20px;
	text-align: center;
}

.edit-customer-btn button {
	width: 60px;
    height: 50px;
    background: '#d9d8d8';
}

.border-custom-modal {
	border-color: #2B94FB!important;
}

.btn-effect-click:active {
	// background-color: #0085E7;
	transform: translateY(2px);
}
